<template>
  <div id="regulation">
    <v-container grid-list-xl fluid>
      <header-title title="Normativas"></header-title>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="showDialog()">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="regulations"
        :loading="loading"
        :search="search"
        calculate-widths
        no-data-text="No existen registros"
        no-results-text="Sin resultados"
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.documento`]="{ item }">
          <td>
            <a
              :href="serve + '/normativas/' + item.documento"
              target="_blank"
              class="link-docs"
            >
              visualizar
            </a>
          </td>
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editRegulation(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmDelete(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="formDialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Normativa</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-flex xs12>
                <v-text-field
                  type="text"
                  label="Cite *"
                  v-model="regulation.cite"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  autocomplete="off"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  type="text"
                  label="Descripción *"
                  rows="2"
                  v-model="regulation.descripcion"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  autocomplete="off"
                  required
                ></v-textarea>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 v-if="mode">
                  <v-file-input
                    type="file"
                    v-model="regulation.archivo"
                    accept="application/pdf"
                    label="Documento *"
                    @change="onFileChange($event)"
                    :rules="[(v) => !!v || 'El documento es requerido']"
                    required
                  ></v-file-input>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-select
                    label="Tipo"
                    :items="type_regulations"
                    v-model="regulation.tipo_normativa_id"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-select
                    label="Estado"
                    :items="states"
                    v-model="regulation.estado"
                    item-text="description"
                    item-value="id"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="mode"
              @click.native="createNormative()"
              >Guardar</v-btn
            >
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              v-show="!mode"
              @click.native="updateRegulation()"
              >Actualizar</v-btn
            >
            <v-btn color="red" text @click.native="formDialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="confirmDialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteRegulation()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="confirmDialog = false"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Storage from "../utils/storage";
import Endpoint from "../utils/environment";
import Service from "../services/request";
import { mapActions } from "vuex";
export default {
  name: "normative",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      loading: false,
      btn_loading: false,
      headers: [
        { text: "#", value: "id", align: "left", sortable: false },
        { text: "Cite ", value: "cite" },
        { text: "Descripción ", value: "descripcion", width: "42%" },
        { text: "Documento ", value: "documento" },
        { text: "Tipo ", value: "tipo_normativa.descripcion" },
        { text: "Estado ", value: "estado" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      regulations: [],
      type_regulations: [],
      regulation: {
        id: "",
        cite: "",
        descripcion: "",
        archivo: null,
        documento: "",
        tipo_normativa_id: "",
        usuario_id: "",
        estado: "",
        location: "",
      },
      serve: "",
      states: [],
      formDialog: false,
      confirmDialog: false,
      mode: true,
    };
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    if (Storage.getUser()) {
      this.regulation.usuario_id = Storage.getUser().uid;
      if (Storage.getUser().role == 1) {
        this.states = Service.getStatus();
        this.getRegulations();
        this.getTypeRegulations();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  methods: {
    ...mapActions(["toast"]),
    onFileChange(event) {
      this.regulation.documento = event;
    },
    getTypeRegulations() {
      Service.svcTypeRegulationParam("get")
        .then((response) => {
          this.loading = false;
          this.type_regulations = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRegulations() {
      this.loading = true;
      Service.svcRegulation("get")
        .then((response) => {
          this.loading = false;
          this.regulations = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showDialog() {
      this.regulation = {
        id: "",
        cite: "",
        descripcion: "",
        archivo: null,
        documento: "",
        tipo_normativa_id: "",
        usuario_id: Storage.getUser().uid,
        estado: true,
        location: "normativas",
      };
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.btn_loading = false;
      this.mode = true;
      this.formDialog = true;
    },
    createNormative() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcRegulation("post", this.regulation, true)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.formDialog = false;
            this.getRegulations();
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    editRegulation(data) {
      this.regulation = data;
      this.mode = false;
      this.formDialog = true;
    },

    updateRegulation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcRegulation("put", this.regulation)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.formDialog = false;
            this.getRegulations();
          })
          .catch(() => {
            this.btn_loading = false;
            this.toast({ color: "error", text: "No se pudo actualizar" });
          });
      }
    },

    confirmDelete(id) {
      this.regulation.id = id;
      this.confirmDialog = true;
    },

    deleteRegulation() {
      this.btn_loading = true;
      Service.svcRegulation("delete", this.regulation)
        .then((response) => {
          this.btn_loading = false;
          if (response.status === 204) {
            this.confirmDialog = false;
            this.toast({ color: "success", text: "Registro eliminado" });
            this.getRegulations();
          } else {
            this.toast({
              color: "error",
              text: "Registro en uso o no existe",
            });
          }
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast({
            color: "error",
            text: "Registro en uso o no existe",
          });
        });
    },
  },
};
</script>
